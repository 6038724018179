import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M 22.5 63.42 L 22.5 22.5 L 27.3 22.5 L 48.78 54.96 L 48.9 54.96 Q 48.78 53.22 48.72 51.24 A 54.3525 54.3525 90 0 1 48.6315 49.6725 Q 48.5985 48.87 48.5805 47.976 A 94.1655 94.1655 90 0 1 48.57 47.37 Q 48.54 45.18 48.54 42.9 L 48.54 22.5 L 53.34 22.5 L 53.34 63.42 L 48.54 63.42 L 27.06 30.84 L 26.94 30.84 Q 27 32.64 27.12 34.62 A 498.1755 498.1755 90 0 1 27.18 36.45 Q 27.21 37.416 27.2385 38.4855 A 787.77 787.77 90 0 1 27.24 38.52 Q 27.3 40.68 27.3 42.96 L 27.3 63.42 L 22.5 63.42 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
